import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { ResponsiveWidthContainer } from "../components/Section";
import Modal from "../components/Modal";
import CareersForm from "../components/CareersForm";

const SectionTitle = styled.h2`
  text-align: left;
  font-size: 3rem;
  color: white;
  margin: 0;
  padding-top: 2rem;
  @media all and (min-width: 480px) {
    padding-top: 5rem;
  }
  font-weight: 400;
  line-height: 3rem;
  flex-grow: 1;
`;

const PageContentStyled = styled(HTMLContent)`
  color: white;
  text-align: left;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
`;

const buttonStyle = `
  color: currentColor;
  background-color: transparent;
  border: 2px solid currentColor;
  cursor: pointer;
  border-radius: 12px;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 400;
  margin: 0 auto;
  border: 1px solid white;
  padding: 10px 20px;
  margin: 2rem auto;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:hover {
    background-color: #fff;
    border-color: #fff;
    color: #000;
  }
`;

const StyledResponsiveWidthContainer = styled(ResponsiveWidthContainer)`
  > section {
    min-height: 670px;
    justify-content: flex-end;
    color: white;
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
  }
`;

export const CareersPageTemplate = ({ title, bgImage, content }) => {
  const [showModal, setShowModal] = useState(false);

  const submitForm = (email, phone, position, message) => {
    alert(`${email}\n${phone}\n${position}\n${message}\n`);
    setShowModal(false);
  };

  return (
    <>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)}>
        <CareersForm onSubmit={submitForm} />
      </Modal>
      <StyledResponsiveWidthContainer
        bgImage={bgImage || null}
        minHeight="770px"
      >
        <SectionTitle>{title}</SectionTitle>
        <PageContentStyled content={content} />
        <button
          onClick={() => setShowModal(true)}
          type="button"
          css={buttonStyle}
        >
          Join us
        </button>
      </StyledResponsiveWidthContainer>
    </>
  );
};

CareersPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  bgImage: PropTypes.string.isRequired,
  content: PropTypes.string
};

const CareersPage = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout location={location}>
      <CareersPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        bgImage={post.frontmatter.backgroundImage}
        content={post.html}
      />
    </Layout>
  );
};

CareersPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object
};

export default CareersPage;

export const careersPageQuery = graphql`
  query CareersPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        backgroundImage
      }
    }
  }
`;
