import React, { useEffect, useRef } from 'react';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const modalStyle = `
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  position: fixed;
  -webkit-overflow-scrolling: auto; 
`;

const Modal = ({ isOpen, onRequestClose, children }) => {
  const modalRef = useRef();
  
  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(modalRef.current)
    } else {
      enableBodyScroll(modalRef.current);
    }
  })
    
  const captureChildrenClicks = (e) => {
    e.stopPropagation();
  }
    
  return (
    <div onClick={onRequestClose} css={isOpen && modalStyle} ref={modalRef}>
      <div onClick={captureChildrenClicks}>
        {isOpen && children}
      </div>
    </div>
  )
};

export default Modal;

