import React, { useRef } from "react";
import PropTypes from "prop-types";

const centerElement = `
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: transform .3s ease-out;
`;

const formStyle = `
  ${centerElement}
  width: 90%;
  max-width: 480px;
  @media all and (min-width: 960px) {
    width: 40%;
    font-size: 1.2rem;
  }
  label {
    display: block;
    padding-bottom: 5px;
    margin-bottom: 0.5rem;
    input, textarea {
      display: block;
      border-radius: 7px;
      margin-top: 10px;
      width: 100%;
      height: 3rem;
    }
    textarea {
      height: 6rem;
    }
    input {
      display: block;
      width: 100%;
      padding: .375rem .75rem;
      font-size: 1rem;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      border: 1px solid #ced4da;
      border-radius: .25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
      margin-bottom: 1rem;
    }
  }
  button {
    color: currentColor;
    display: block;
    margin-top: 2rem;
    margin-left: auto;
    margin-right: 0;
    background-color: transparent;
    border: 1px solid currentColor;
    border-radius: 10px;
    padding: 0.6rem 1.5rem;
    cursor: pointer;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    &:hover {
      background-color: #fff;
      border-color: #fff;
      color: #000;
    }
  }
`;

const CareersForm = ({ onSubmit }) => {
  const emailRef = useRef();
  const phoneRef = useRef();
  const positionRef = useRef();
  const messageRef = useRef();

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(
      emailRef.current.value,
      phoneRef.current.value,
      positionRef.current.value,
      messageRef.current.value
    );
  };

  return (
    <form css={formStyle} onSubmit={handleSubmit}>
      <label>
        Name
        <input type="text" required ref={emailRef} />
      </label>
      <label>
        Phone
        <input type="tel" required ref={phoneRef} />
      </label>
      <label>
        Position of interest
        <input type="text" required ref={positionRef} />
      </label>
      <label>
        Message
        <textarea required ref={messageRef} />
      </label>
      <button type="submit">Join us</button>
    </form>
  );
};

CareersForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default CareersForm;
